import React from "react"
import { motion } from "framer-motion"

import { TransitionProps } from "./ITransition"
import { useSetRouteNavState } from "../hooks/NavState"
import { useWindowSize, isBreakpointMore } from "../hooks/WindowSize"
import "./FirstLevelPage.scss"

const variants = {
  enterStart: {
    translateX: "100%",
  },
  enter: {
    translateX: 0,
    transition: {
      delay: 0.3,
    },
  },
  leave: {
    translateY: "15%",
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
}

interface Props extends TransitionProps {
  className?: string
  useInnerPage?: boolean
}

const FirstLevelPage: React.FC<Props> = ({
  children,
  transitionStatus,
  className,
  useInnerPage = true,
}) => {
  const windowSize = useWindowSize()
  useSetRouteNavState(
    () => (isBreakpointMore("lg", windowSize) ? "open" : "closed"),
    transitionStatus,
    () => {
      return isBreakpointMore("lg", windowSize)
        ? {
            to: "/",
            icon: "back",
          }
        : {
            toggle: [
              {
                icon: "open",
                state: "closed",
                to: "open",
              },
              {
                icon: "close",
                state: "open",
                to: "closed",
              },
            ],
          }
    },
    windowSize
  )
  let animate, initial
  switch (transitionStatus) {
    case "exiting":
      animate = "leave"
      initial = "enter"
      break
    default:
      animate = "enter"
      initial = "enterStart"
      break
  }

  return (
    <motion.div
      className={`page--first-level page__content theme-dark ${className ||
        ""}`}
      initial={initial}
      animate={animate}
      variants={variants}
    >
      <div className={`${useInnerPage ? "page__content-inner" : ""}`}>
        {children}
      </div>
    </motion.div>
  )
}

export default FirstLevelPage
