import React from "react"

import SEO from "../components/Seo"
import FirstLevelPage from "../components/FirstLevelPage"

const NotFoundPage = () => (
  <FirstLevelPage>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </FirstLevelPage>
)

export default NotFoundPage
